<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button></p>
                <div class="h-b"><el-button type="primary" size="small" @click="addCustomerAddress" v-right-code="'Customeraddress:Save'">新增</el-button></div>
                <div class="h-b"><el-button type="text" size="small" @click="saveCustomerAddress" v-right-code="'Customeraddress:Save'">保存</el-button></div>
            </div>
        </div>

        <div class="form-list">
            <el-form ref="_customerAddressForm" :model="dataSource" :rules="customerAddressCheckRule">
                <!--<el-form-item>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>客户名称：</el-col>
                    <el-col :span="7">
                        <el-input v-model.trim="dataSource.CustomerName" disabled :maxlength="30" :minlength="4" placeholder="客户名称"></el-input>
                    </el-col>
                    <el-col :span="3" class="form-title">客户编号</el-col>
                    <el-col :span="7" class="form-title">
                        <el-input v-model.trim="dataSource.CustomerCode" disabled :maxlength="30" :minlength="4" placeholder="客户编号"></el-input>
                    </el-col>
                </el-form-item>-->
				
				<el-form-item>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主名称：</el-col>
					<el-col :span="7">
						<el-form-item prop="CustomerName">
						<!-- 	<el-input v-model="dataSource.EnterpriseName" :maxlength="30" :minlength="4"
								placeholder="客户名称"></el-input> -->
								<el-select v-model="dataSource.CustomerCode" collapse-tags placeholder="请选择"
									:maxlength="200" :disabled="isDisabled">
									<el-option v-for="item in dataSource.CustomerList" :key="item.Key"
										:label="item.Value" :value="item.Key">
									</el-option>
								</el-select>
								
						</el-form-item>
					</el-col>
					<div :hidden="!isDisabled">
						<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主编码：</el-col>
						<el-col :span="7">
							<el-form-item prop="CustomerCode">
								<el-input v-model="dataSource.CustomerCode" :maxlength="30" :minlength="4"
									:disabled="true" placeholder="客户编码"></el-input>
							</el-form-item>
						</el-col>
					</div>
				</el-form-item>
				
                <el-form-item>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>客商名称：</el-col>
					<el-col :span="7">
						<el-form-item prop="VendorName">
                            <LookPopup v-model="dataSource.VendorName" dataTitle="客商选择"
                                        dataUrl="omsapi/customervendor/getlist" syncField='VendorName'
                                        @syncFieldEvent="syncCustomerVendorEvent">
                                    </LookPopup>
						</el-form-item>
					</el-col>
				</el-form-item>

                <el-form-item>
                    <el-col :span="3" class="form-title">地址编码：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="AddressCode">
                            <el-input v-model.trim="dataSource.AddressCode" :maxlength="64" placeholder="地址编码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">收货方名称：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="CompanyName">
                            <el-input v-model.trim="dataSource.CompanyName" :maxlength="64" placeholder="收货方名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>联系人姓名：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ContactName">
                            <el-input v-model.trim="dataSource.ContactName" :maxlength="64" placeholder="联系人姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>联系人电话：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ContactPhone">
                            <el-input v-model.trim="dataSource.ContactPhone" :maxlength="128" placeholder="联系人电话"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span>地区：
                    </el-col>
                    <el-col :span="17">
                        <Distpicker :placeholders="dataSource"  :showTown="true"></Distpicker>
                    </el-col>

                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span>详细地址：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="CustomerAddressDetail">
                            <el-input v-model.trim="dataSource.CustomerAddressDetail" :maxlength="200" placeholder="详细地址"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
	
    export default {
        data() {
            var checkContactName = function (rule, value, callback) {
                if (!value) return callback(new Error('联系人姓名不能为空'));
                callback();
            };
            var checkContactPhone = (rule, value, callback) => {
                if (!value) 
                    return callback(new Error('联系电话不能为空'));
                else if(!this.Utils.isMobile(value))
                    return callback(new Error('联系电话格式不正确.'));
                else
                    return callback();
            };
            var checkCustomerAddressDetail = function (rule, value, callback) {
                if (!value) return callback(new Error('详细地址不能为空'));
                callback();
            };
            return {
                customerAddressOptions: {
                    visible: false,
                    size: 'large'
                },
                tCustomerConfig: {
                    isDetailDisplay: Object.freeze(true)
                },
                multipleSelection: [],
                isDisabled: false,
                customerAddressCheckRule: {
                    ContactName: [{ validator: checkContactName, trigger: 'blur' }],
                    ContactPhone: [{ validator: checkContactPhone, trigger: 'blur' }],
                    CustomerAddressDetail: [{ validator: checkCustomerAddressDetail, trigger: 'blur' }]
                },
                currentSelectedRow: {}
            }
        },
        props: {
            config: {
                isDetailDisplay: false
            },
            dataSource: {}
        },
        mounted() {
            this.Event.$on("clearEditCustomerAddressForm", () => this.resetForm);
        },
        watch: {
            dataSource: {
                handler(curVal, oldVal) {
                    this.isDisabled = null != this.dataSource.CustomerAddressID;
                },
                deep: true
            }
        },
        created: function () {
            this.Event.$on("clearForm", this.resetForm);
        },
        methods: {
            syncCustomerVendorEvent(row){
                if (row) {
                    this.dataSource.VendorId = row.VendorId;
                }
            },
            addCustomerAddress() {
                this.Event.$emit("onAddCustomerAddress");
            },
            resetForm() {
                !this.isPassValidate && this.$refs['_customerAddressForm'].resetFields();//清空表单
            },
            saveCustomerAddress() {
                var _this = this;

                _this.$refs["_customerAddressForm"].validate((valid) => {
                    _this.isPassValidate = valid;
                    if (valid) {
                        var routeName = _this.dataSource.CustomerAddressID === null ? "create" : "save";
                        _this.dataSource.SupplierType = 100;
                        _this.dataSource.AddressType = 200;
                        _this.$ajax.send("omsapi/customeraddress/" + routeName, "post", _this.dataSource, (data) => {
                            _this.dataSource.CustomerAddressID === null && (_this.dataSource.CustomerAddressID = data.Result);
                            _this.$parent.syncDataSource();
                            _this.Event.$emit("reloadCustomerAddressPageList", data.Result);
                            _this.Utils.messageBox("保存成功.", "success");
                        });
                    } else {
                        return false;
                    }
                });
            },
            onCustomerAddressSelectedRow: function (row) {
                this.currentSelectedRow = row;
            }

        }
    }

</script>

<style>
</style>